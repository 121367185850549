<template>
  <div class="box has-max-width">
    <Heading size="5" :text="$t('profile.settings.payment.title')" />

    <p>
      {{ $t('profile.settings.payment.helpText') }}
    </p>

    <Button
      type="primary"
      :title="$t('profile.settings.payment.openStripe')"
      :loading="isLoading"
      @click="openStripe">
    </Button>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isLoading: false
    }
  },

  methods: {
    async openStripe () {
      this.isLoading = true

      try {
        const session = await this.$store.dispatch('subscription/getBillingPortalSession', this.$route.path)
        location.href = session.url
      } catch (error) {
        this.isLoading = false
        console.error(error)
        this.$notification.danger(this.$i18n.t('profile.settings.payment.getPortalSessionFailedErrorMessage'))
      }
    }
  }
}
</script>
